<template>
  <div class="columns">
    <div class="column">

    </div>
    <div class="column">

    </div>
    <div class="column">

    </div>
  </div>
  <div class="columns">
    <div class="column">

    </div>
    <div class="column">
      <div class="box">
        <form v-on:submit.prevent="formSubmit">
          <div class="field">
            <label class="label">
              Email:
            </label>
            <div class="control">
              <input class="input" type="email" v-model="email" placeholder="Email" required>
            </div>
          </div>
          <div class="field">
            <label class="label">
              Name:
            </label>
            <div class="control">
              <input class="input" type="text" v-model="name" placeholder="Name" required>
            </div>
          </div>
          <div class="field">
            <label class="label">
              Message:
            </label>
            <div class="control">
              <textarea class="textarea" v-model="message" placeholder="Message" required></textarea>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button class="button is-link" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="column">

    </div>
  </div>
  <div class="columns">
    <div class="column">

    </div>
    <div class="column">

    </div>
    <div class="column">

    </div>
  </div>

</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      nameError: "",
      emailError: "",
      messageError: "",
      nameValid: false,
      emailValid: false,
      messageValid: false,
      formValid: false,
      formError: "",
      formSuccess: "",
      formSubmitted: false,
    }
  },
  methods:{
    formSubmit: function (e) {
      e.preventDefault();
      this.validateForm();
      if (this.formValid) {
        this.formSubmitted = true;
        this.formError = "";
        this.formSuccess = "Thank you for your message. We will get back to you shortly.";
      } else {
        this.formError = "Please fill out all required fields.";
      }
    },
    validateForm: function () {
      this.nameValid = this.name.length > 0;
      this.emailValid = this.email.length > 0;
      this.messageValid = this.message.length > 0;
      this.formValid = this.nameValid && this.emailValid && this.messageValid;
    }
  }
}
</script>

<style scoped>

</style>
